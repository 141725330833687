import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "Our app",
  "title": "Sphere - Group Chat",
  "description": "Transform your group into a vibrant community",
  "image": "/cloudinary/sphere/image/upload/v1626970076/website/images/Website_thumbnail.png",
  "image - SEO/link preview": "/cloudinary/sphere/image/upload/v1626970076/website/images/Website_thumbnail.png"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const JoinWaitlistModal = makeShortcode("JoinWaitlistModal");
const Form = makeShortcode("Form");
const FormInputText = makeShortcode("FormInputText");
const FormInputEmail = makeShortcode("FormInputEmail");
const Paragraph = makeShortcode("Paragraph");
const Link = makeShortcode("Link");
const FormSubmitButton = makeShortcode("FormSubmitButton");
const JoinWaitlistFollowUpModal = makeShortcode("JoinWaitlistFollowUpModal");
const WaitlistFollowUpModalHeader = makeShortcode("WaitlistFollowUpModalHeader");
const SectionHeaderImage = makeShortcode("SectionHeaderImage");
const QuestionForm = makeShortcode("QuestionForm");
const FormQuestion = makeShortcode("FormQuestion");
const FormAnswer = makeShortcode("FormAnswer");
const JoinWaitlistFollowUpSuccessModal = makeShortcode("JoinWaitlistFollowUpSuccessModal");
const SuccessModalHeader = makeShortcode("SuccessModalHeader");
const Hero = makeShortcode("Hero");
const HeroMedia = makeShortcode("HeroMedia");
const Video = makeShortcode("Video");
const VideoSection = makeShortcode("VideoSection");
const Feature = makeShortcode("Feature");
const JoinWaitlistBanner = makeShortcode("JoinWaitlistBanner");
const Missions = makeShortcode("Missions");
const MissionShapeItem = makeShortcode("MissionShapeItem");
const FAQS = makeShortcode("FAQS");
const Question = makeShortcode("Question");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <JoinWaitlistModal name={'join-waitlist'} title={'Join waitlist'} subtitle={'Sign up for our beta and we’ll keep you updated on progress.'} mdxType="JoinWaitlistModal">
      <Form name={'join-waitlist'} errorMessage={'There was an error while joining the waitlist. Please try later.'} successActionType={'None'} submitText={'Submit'} mdxType="Form">
	<FormInputText name={'firstName'} placeholder={'First Name'} required={true} mdxType="FormInputText"></FormInputText>
	<FormInputEmail name={'email'} placeholder={'Your Email'} errorMessage={'Invalid email address'} mdxType="FormInputEmail"></FormInputEmail>
	<Paragraph size={'small'} $textAlign={'center'} mdxType="Paragraph">
		By clicking "submit" you accept our{' '}
		<Link text={'Terms and Conditions'} url={'https://sphere.me/terms'} target={'_blank'} mdxType="Link"></Link> and <Link text={'Privacy Policy.'} url={'https://sphere.me/privacy'} target={'_blank'} mdxType="Link"></Link>
	</Paragraph>
	<FormSubmitButton text={'Submit'} $fullWidth={false} mdxType="FormSubmitButton" />
      </Form>
    </JoinWaitlistModal>
    <JoinWaitlistFollowUpModal name={'join-waitlist-follow-up'} subtitle={'Tell us more about your group to move up the waitlist – just 3 quick questions!'} mdxType="JoinWaitlistFollowUpModal">
	<WaitlistFollowUpModalHeader mdxType="WaitlistFollowUpModalHeader">
		Great! You’ve been added to our
		<SectionHeaderImage src={'/cloudinary/sphere/image/upload/v1624898102/website/images/added-to-waitlist-icon.png'} mdxType="SectionHeaderImage" />
		waitlist.
	</WaitlistFollowUpModalHeader>
	<QuestionForm name={'join-waitlist-follow-up'} mdxType="QuestionForm">
		<FormQuestion type={'multiple-choice'} label={'Question 1/3'} title={"How big is the group you'd like to use Sphere for?"} imageUrl={'/cloudinary/sphere/image/upload/v1624544003/website/images/question_1_image.png'} required={true} mdxType="FormQuestion">
			<FormAnswer value={'1-10'} label={'1 - 10'} mdxType="FormAnswer" />
			<FormAnswer value={'11-50'} label={'11 - 50'} mdxType="FormAnswer" />
			<FormAnswer value={'51-100'} label={'51 - 100'} mdxType="FormAnswer" />
			<FormAnswer value={'101-250'} label={'101 - 250'} mdxType="FormAnswer" />
			<FormAnswer value={'251 - 500'} label={'251 - 500'} mdxType="FormAnswer" />
			<FormAnswer value={'More than 500'} label={'More than 500'} mdxType="FormAnswer" />
                        <FormAnswer value={'I’m not part of a group'} label={'I’m not part of a group'} mdxType="FormAnswer" />
		</FormQuestion>
		<FormQuestion type={'multiple-choice'} label={'Question 2/3'} title={'What type of group is it?'} imageUrl={'/cloudinary/sphere/image/upload/v1624544003/website/images/question_2_image.png'} required={true} mdxType="FormQuestion">
			<FormAnswer value={'Sports club'} label={'Sports club'} mdxType="FormAnswer" />
			<FormAnswer value={'Professional network'} label={'Professional network'} mdxType="FormAnswer" />
			<FormAnswer value={'Activist group or charity'} label={'Activist group or charity'} mdxType="FormAnswer" />
			<FormAnswer value={'Student society'} label={'Student society'} mdxType="FormAnswer" />
			<FormAnswer value={'Hobby group'} label={'Hobby group'} mdxType="FormAnswer" />
			<FormAnswer value={'Religious group'} label={'Religious group'} mdxType="FormAnswer" />
			<FormAnswer value={'Friend group'} label={'Friend group'} mdxType="FormAnswer" />
			<FormAnswer value={'Other'} label={'Other'} mdxType="FormAnswer" />
			<FormAnswer value={'I’m not part of a group'} label={'I’m not part of a group'} mdxType="FormAnswer" />
		</FormQuestion>
		<FormQuestion type={'checkboxes'} label={'Question 3/3'} title={'Which apps do your group use now?'} imageUrl={'/cloudinary/sphere/image/upload/v1624544003/website/images/question_3_image.png'} required={true} mdxType="FormQuestion">
			<FormAnswer value={'WhatsApp'} label={'WhatsApp'} mdxType="FormAnswer" />
			<FormAnswer value={'Facebook Messenger'} label={'Facebook Messenger'} mdxType="FormAnswer" />
			<FormAnswer value={'Facebook Group'} label={'Facebook Group'} mdxType="FormAnswer" />
			<FormAnswer value={'Discord'} label={'Discord'} mdxType="FormAnswer" />
			<FormAnswer value={'Slack'} label={'Slack'} mdxType="FormAnswer" />
			<FormAnswer value={'Microsoft Teams'} label={'Microsoft Teams'} mdxType="FormAnswer" />
			<FormAnswer value={'Signal'} label={'Signal'} mdxType="FormAnswer" />
			<FormAnswer value={'Telegram'} label={'Telegram'} mdxType="FormAnswer" />
			<FormAnswer value={'Meetup'} label={'Meetup'} mdxType="FormAnswer" />
			<FormAnswer value={'Eventbrite'} label={'Eventbrite'} mdxType="FormAnswer" />
			<FormAnswer value={'Email'} label={'Email'} mdxType="FormAnswer" />
			<FormAnswer value={'Other'} label={'Other'} mdxType="FormAnswer" />
                        <FormAnswer value={'I’m not part of a group'} label={'I’m not part of a group'} mdxType="FormAnswer" />
		</FormQuestion>
		<FormSubmitButton text={'Submit'} $fullWidth={false} mdxType="FormSubmitButton" />
    </QuestionForm>
    </JoinWaitlistFollowUpModal>
    <JoinWaitlistFollowUpSuccessModal name={'join-waitlist-success-modal'} subtitle={'We’ll let you know when the beta’s ready for you.'} buttonText={'Got it'} mdxType="JoinWaitlistFollowUpSuccessModal">
	<SuccessModalHeader mdxType="SuccessModalHeader">
		Thanks!
		<SectionHeaderImage src={'/cloudinary/sphere/image/upload/v1625151511/website/images/thank-you-waitlist.svg'} mdxType="SectionHeaderImage" />
		You’re all set
	</SuccessModalHeader>
    </JoinWaitlistFollowUpSuccessModal>
    <Hero name={'homepage-hero'} title={'Group chat that brings people closer together'} buttonText={'Join waitlist'} buttonToggleTargetName={'join-waitlist'} subtitle={`
Sphere transforms groups into vibrant communities. Whilst the apps have been wound down, exciting community work continues at Twitter.`} mdxType="Hero">
	<HeroMedia mdxType="HeroMedia">
		<Video src={'/cloudinary/sphere/video/SphereWeb.mp4'} mdxType="Video" />
	</HeroMedia>
    </Hero>
    <VideoSection title={'Bring out the best in your sports club, religious group, professional society, academy of wizards, or hey, even your friend group.'} mdxType="VideoSection" />
    <Feature name={'important-messages'} header={'More talk, less chaos'} lottieUrl={'/cloudinary/sphere/raw/upload/v1624369842/lotties/chats3.json'} text={'Sphere turns messy back-and-forths into a few simple, unmissable posts, so you can keep chats lively without blowing up everyone’s phone. ✨'} lottieLoop={true} mdxType="Feature" />
    <Feature name={'chat-flow'} header={'Zone in with Zen Flow'} lottieUrl={'/cloudinary/sphere/raw/upload/v1624880075/lotties/chat_flow.json'} text={'Out with the old, and out with anything not for you. Zen Flow is a new way to see just the convos you care about and hide the rest on your feed, without losing anything. 🏖️'} lottieLoop={true} mdxType="Feature" />
    <Feature name={'appreciations'} header={'Share the love, your way'} lottieUrl={'/cloudinary/sphere/raw/upload/v1624880567/lotties/appriciations.json'} text={'Every group has its own love language, from offering carpools to organising pub nights. On Sphere, you can create custom Appreciations to recognise these small but mighty acts of kindness. 🏆'} lottieLoop={false} mdxType="Feature" />
    <JoinWaitlistBanner ctaText={'Join waitlist'} mdxType="JoinWaitlistBanner">
	We’re building Sphere now. Be one of the first
	<SectionHeaderImage src={'/cloudinary/sphere/image/upload/v1625216709/website/images/flag-in-hand.svg'} mdxType="SectionHeaderImage" />
	groups to get it.
    </JoinWaitlistBanner>
    <Missions title={'This is so, so much bigger than chat.'} mdxType="Missions">
	<MissionShapeItem header={'Groups are weird, magical places where people unite.'} ctaText={'Tell me more'} text={'When people get together in real life, something interesting happens. We’re kind to strangers. We help each other talk more and listen more.'} shapeUrl={'/cloudinary/sphere/image/upload/v1623765690/missions_circle.svg'} mdxType="MissionShapeItem"></MissionShapeItem>
	<MissionShapeItem header={'But on social media we’re more divided than ever.'} ctaText={'How so?'} text={'We forget to treat each other like we naturally would in real life.'} shapeUrl={'/cloudinary/sphere/image/upload/v1623761680/Group_190_zk65iw.svg'} mdxType="MissionShapeItem"></MissionShapeItem>
	<MissionShapeItem header={'What if we could bring out more group magic online?'} ctaText={'Go on ...'} text={'What if there was a way that people could truly come together to achieve more?'} shapeUrl={'/cloudinary/sphere/image/upload/v1623765768/missions_shape.svg'} mdxType="MissionShapeItem"></MissionShapeItem>
	<MissionShapeItem header={'That’s why we’re building Sphere.'} ctaText={'Say more'} text={'A community of communities. Sure, an app can’t change the world alone. But we all can, by bringing out the best in each other.'} shapeUrl={'/cloudinary/sphere/image/upload/v1623765979/missions_rectangle.svg'} mdxType="MissionShapeItem"></MissionShapeItem>
    </Missions>
    <FAQS title={'FAQ'} expandedQuestionKey={'0'} mdxType="FAQS">
	<Question question={'What is Sphere?'} answer={'A free chat app for groups, designed to foster close, vibrant, purposeful and productive communities.'} mdxType="Question" />
	<Question question={'Can I still use the Sphere apps?'} answer={"The Sphere team is now working full-time on building world-class communities at Twitter. This means that we are no longer accepting new groups and we'll spend a period of time helping our existing groups to find new homes for their communities."} mdxType="Question" />
	<Question question={'Why is Sphere moving to Twitter?'} answer={"Sphere and Twitter both believe in the power of people connecting over shared interests. Joining Twitter means we can apply all our knowledge at scale to Twitter's already strong foundation, so we can provide even more meaningful connections online."} mdxType="Question" />
    </FAQS>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      